









































import { Component, Vue, Watch } from 'vue-property-decorator';
import { IBlacklistAddress, IBlacklistSearchParams } from '@/interfaces';
import { dispatchGetBlacklistAddresses, dispatchDeleteBlacklistAddresses } from '@/store/main/accessors';
import { readHasAdminAccess } from '@/store/main/accessors';

@Component
export default class BlacklistAddresses extends Vue {
  public blacklistId: string | null = null;
  public search: string = '';
  public loading: boolean = false;
  public blacklistAddresses: IBlacklistAddress[] = [];
  public total: number = 0;
  public selected: IBlacklistAddress[] = [];

  public range = [];
  public sender: string = '';
  public receiver: string = '';

  public pagination: { sortBy; descending; page; rowsPerPage; footerProps } = {
    sortBy: ['id'],
    descending: [false],
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };

  public async updateBlacklistAddresses(clearPagination?: boolean) {
    if (clearPagination) {
      this.pagination.page = 1;
    }
    this.loading = true;
    const data = await this.getDataFromApi();
    this.total = data.total;
    this.blacklistAddresses = data.items;
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public async onPaginationChange(val) {
    await this.updateBlacklistAddresses();
  }

  public get headers() {
    const headers = [
      {
        text: this.$vuetify.lang.t('$vuetify.address'),
        value: 'address',
        align: 'left',
      },
    ];

    return headers;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async mounted() {
    await this.updateBlacklistAddresses();
  }

  public async getDataFromApi() {
    const { sortBy, descending, page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    const params: IBlacklistSearchParams = {
      skip,
      limit: rowsPerPage,
      orderBy: sortBy[0],
      descending: descending[0],
      blacklistId: this.$attrs?.id,
    };
    return await dispatchGetBlacklistAddresses(this.$store, params);
  }

  public async deleteBlacklistAddresses() {
    if (confirm(this.$vuetify.lang.t('$vuetify.deleteServicesConfirmation'))) {
      const payload = this.selected.map((item) => item.address);
      const id = Number(this.$router.currentRoute.params.id);
      await dispatchDeleteBlacklistAddresses(this.$store, {
        id,
        payload,
      });
      this.selected = [];
      await this.updateBlacklistAddresses();
    }
  }
}
